.page {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.page-title {
  max-width: 100%;
}

.page-header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sample-survey-tag {
  font-size: 1.15rem !important;
  height: 2.25rem !important;
}

.language-and-save-container {
  height: 2.813rem;
  gap: 0.5rem;
  margin: 1rem 0;
}

.response-code {
  font-family: 'Fira Sans';
  font-variant-numeric: slashed-zero;
  font-weight: 400;
}

.save-response-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media only screen and (min-width: 1024px) {
  .page-header-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .language-and-save-container {
    min-width: 15.625rem;
    justify-content: end;
    margin: 0;
  }
}

.load-section {
  display: flex;
  gap: 1rem;
}

.w-100 {
  width: 100%;
}