.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem;

}

.actions-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: .5rem;
}

.version-selection-section {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.version-select-label {
  margin: auto .75rem auto 0;
}

.version-buttons-container {
  display: flex;
  gap: .5rem;
}

@media screen and (min-width: 1356px) {
  .actions-container {
    width: auto;
  }
}